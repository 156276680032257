<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">Referral Users</h1>

    <div v-if="reportReferrers" class="statistics box box--white box--xl mt-16">
      <statistic
        title="Total amount in TOKO"
        :isRating="false"
        :value="reportReferrers.totalCreditsToko | float_number_with_commas"
        valueClass="headline--xl text-purple"
      />

      <statistic
        title="Total amount in USD"
        :isRating="false"
        :value="reportReferrers.totalCreditsUsd | float_number_with_commas"
        valueClass="headline--xl text-purple"
      />

      <statistic
        title="Total Users used Referral link"
        :isRating="false"
        :value="reportReferrers.totalUserReferred"
        valueClass="headline--xl text-purple"
      />
    </div>

    <div v-if="reportReferrers" class="box box--sm box--gray radius-bottom-0 mt-16 d-flex justify-end">
      <search-box class="mr-8" v-model="searchText" holder-text="Search by email" @on-search="onGetReportReferrers" />

      <selection-box
        v-model="params.perPage"
        :options="pagerOptions"
        :is-align-right="true"
        :on-check="onGetReportReferrers"
      />
    </div>

    <div v-if="reportReferrers" class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0">
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Acc. ID</th>
            <th>Full Name</th>
            <th>Email Address</th>
            <th>Mobile Number</th>
            <th>Amount in USD/TOKO</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            v-for="referrer in reportReferrers.dataReports"
            :key="referrer.user.id"
            :to="{ name: 'UserReferrer', params: { id: referrer.user.id } }"
            class="link--clone"
            tag="tr"
          >
            <td>{{ referrer.user.id }}</td>
            <td>{{ referrer.user.fullName }}</td>
            <td class="clipboard-copy">
              <span class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ referrer.user.email }}</span>
                <copy-clipboard :is-external="false" tooltip-class="custom" @on-copy="doCopy($event)" />
              </span>
            </td>
            <td>{{ referrer.user.countryCode }} {{ referrer.user.phone }}</td>
            <td class="w-168 text-bold">
              {{ referrer.creditUsd | float_number_with_commas }} USD /<br />{{
                referrer.creditToko | float_number_with_commas
              }}
              TOKO
            </td>
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ reportReferrers.total | format_user_count_text }} in total</p>

        <pagination
          v-if="isNextPage(reportReferrers.total)"
          v-model="page"
          :page-count="getPageCount(reportReferrers.totalPages, reportReferrers.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        ></pagination>
      </div>
    </div>

    <p v-else class="mt-16">No referral data</p>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import pagination from '@/mixins/pagination'
import handleAssets from '@/mixins/handle-assets'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
import { PAGER_OPTIONS } from '@/utils/data-sets'
const Statistic = () => import('@/components/Statistic')
const Pagination = () => import('@/components/Pagination')
const SelectionBox = () => import('@/components/SelectionBox')
const SearchBox = () => import('@/components/SearchBox')
const CopyClipboard = () => import('@/components/CopyClipboard')

export default {
  data() {
    return {
      params: {
        perPage: 1,
        sortBy: 'credit_toko'
      },
      page: 1,
      searchText: '',
      pagerOptions: PAGER_OPTIONS
    }
  },

  mixins: [pagination, handleAssets, toggleLoading, copyClipboard],

  components: {
    Statistic,
    Pagination,
    SelectionBox,
    SearchBox,
    CopyClipboard
  },

  computed: {
    ...mapState('referral', ['reportReferrers', 'assets'])
  },

  methods: {
    ...mapActions('referral', ['getReportReferrers']),

    onGetReportReferrers() {
      this.toggleLoading()
      this.page = 1
      this.getReportReferrers(this.getRequestParams()).finally(() => {
        this.toggleLoading()
      })
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getReportReferrers(this.getRequestParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getRequestParams(params = this.params) {
      return {
        page: this.page,
        email: this.searchText,
        sortBy: params.sortBy,
        limit: params.perPage
      }
    }
  },

  created() {
    this.getReportReferrers(this.getRequestParams()).finally(() => {
      this.toggleLoading()
    })
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'UserReferrer') {
      this.storeAssets('referral')
      next()
    } else {
      this.clearAssets('referral')
      next()
    }
  }
}
</script>
